import * as React from "react";
import Layout from "../components/Layout/en";

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <div style={{display: "flex", height: "calc(100vh - 200px)", justifyContent: "center", alignItems: "center"}}>
      <h1 style={{fontSize: "5em"}}>404</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
